import activity from './m-monitor-participants.vue';

export default activity;

export const componentRegistrationData = {
  name: 'Monitor Participants',
  icon: 'mdi-monitor-dashboard',
  description: 'Learn how to monitor your participants',
  status: true,
  styling: {
    borderColor: '#3c9dcd'
  },
  setupRequired: true,
  fields: [
    {
      roles: ['employer', 'participant'],
      name: 'm-monitor-participants',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {
        link: ''
      },
      value: {}
    },
    {
      roles: ['employer', 'participant'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {},
      value: {}
    }
  ]
};
